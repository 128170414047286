$(document).ready(function () {

	/******************************************************************************************
	DEBUGGING STUFF
	*******************************************************************************************/

	var debug = true;
	browserLog = function(text) {
		$('.dev-tools__log__text ').append('<br />' + text);
		$('.dev-tools__log').show();
	}

	/******************************************************************************************
	CHECK FOR OLD IE
	******************************************************************************************/

	var old_ie = false;
	
	if ($("html").hasClass("ie8") || $("html").hasClass("ie7")) {
		old_ie = true;
	}
	
	/******************************************************************************************
	SCREEN SIZE CHANGES
	******************************************************************************************/
	
	var medium_screen = 650,
		large_screen = 1100,
		screen_size;

	set_screen_size = function () {
		var prev_screen_size = screen_size;
		if (Modernizr.mq('only all and (min-width: ' + large_screen + 'px)') && screen_size != "large") {
			screen_size = "large";
			// actions for when switching to large screen here

		} else if (Modernizr.mq('only all and (min-width: ' + medium_screen + 'px) and (max-width: ' + (large_screen - 1) + 'px)') && screen_size != "medium") {
			screen_size = "medium";
			// actions for when switching to medium screen here

		} else if (Modernizr.mq('only all and (max-width: ' + (medium_screen - 1) + 'px)') && screen_size != "small") {
			screen_size = "small";
			// actions for when switching to small screen here
			
		}
	};

	if (!old_ie) {
		$(window).resize(function() {
			set_screen_size();
		});
		set_screen_size();
	}

	/******************************************************************************************
	SVG FALLBACKS
	******************************************************************************************/

	if (!Modernizr.svg) {
		$("svg").each(function() {
			if ($(this).attr("data-fallback")) {
				$(this).after('<img src="' + $(this).attr("data-fallback") + '" class="' + $(this).attr("class") + '" />');
				$(this).remove();
			}
		})
		$("img[src*='.svg'").each(function() {
			var src = $(this).attr("src");
			src = src.replace(".svg", ".png");
			src = src.replace("/site/", "/site/_png/");
			$(this).attr("src", src);
		});
	}
	
	/******************************************************************************************
	STICKY NAV
	******************************************************************************************/

	var header = $('.header__js'),
		topHeader = $('.section__header-top'),
		mainContent = $('.main-content'),
		oldScrollPos = 0;
	$(window).on('scroll resize load', (function() {

		// headerOffset is used when header doesn't start at top of viewport, eg WJ home page
		var headerHeight = header.outerHeight(),
		topHeaderHeight = topHeader.outerHeight(),
		currentScrollPos = $(window).scrollTop();

		mainContent.css('padding-top', headerHeight);

		// if top
		if (currentScrollPos == 0) {
				header.addClass('header__js--show');
		} // if we're at top of page
				else if (currentScrollPos <= headerHeight) {
					header.removeClass('header__js--hidden');
		} // if scroll up
		else if (currentScrollPos < oldScrollPos) {
					header.removeClass('header__js--hidden').addClass('header__js--show');
		} // if scroll down
		else {
				header.addClass('header__js--hidden').removeClass('header__js--show');
		}

		// header position change
		function headerPosition() {
			if (header.hasClass('header__js--show')) {
				header.css({
					top: -topHeaderHeight, // option for different height hide
					// top: '',
				}); 
			} else {
				header.css({
					top: -headerHeight,
				})
			}
		}
		// when scroll down
		if (currentScrollPos < oldScrollPos) {
			if (currentScrollPos <= topHeaderHeight) { // option for different height hide
			// if (currentScrollPos <= 0) {
				header.removeClass('header__js--show');
				header.css({
						top: '',
				});
			} else {
				headerPosition();
			}
		}
		// when scroll up
		else {
			if (currentScrollPos <= headerHeight) {
				header.removeClass('header__js--show');
				header.css({
						top: '',
				});
			} else {
				headerPosition();
			}
		}
		
		oldScrollPos = currentScrollPos;

	}));
	
	/******************************************************************************************
	FILTERS (JUST ON WORK FOR NOW)
	******************************************************************************************/
	
	$(".filters").each(function() {
		var filtersPanel = $(this);
		var filtersBtn = $(".js-filter-btn");
		var filtersCloseBtn = $(this).find(".filters__close");
		// click button to open panel
		$(filtersBtn).click(function(e) {
			filtersPanel.fadeIn(200);
		});
		// click page bg or close button to close
		filtersPanel.add(filtersCloseBtn).click(function(e) {
			filtersPanel.fadeOut(200);
		});
		// stop clicks on panel itself from going through to bg
		filtersPanel.find(".filters__panel").click(function(e) {
			e.stopImmediatePropagation();
		});
	})

	/******************************************************************************************
	PARALLAX
	******************************************************************************************/
	
	var doingParallax = $('html').hasClass('no-touchevents');

	if (doingParallax) {
		$(".parallax").each(function() {
			var pContainer = $(this);
			var pContent = $(this).children();
			$(window).on("scroll resize load", function() {
				
				var pDistance = pContainer.height() + $(window).height(); // total distance parallax effect will happen over
				var pStartAt = pContainer.offset().top - $(window).height() ; // scroll position where parallax effect will begin
				var scrollPos = $(window).scrollTop();
				var pAmount = Math.min(Math.max((pDistance + pStartAt - scrollPos) / pDistance, 0), 1);
				var viewportWidth = $(window).width();

				// if parallax object is out of view, don't bother changing CSS on it...
				if (scrollPos < pStartAt || scrollPos > pStartAt + pDistance) {
					return;
				}
				
				pContent.css({
					transform: 'translateY(' + (-pAmount * 20) * (viewportWidth / 100) + 'px)'
				})
			})
		})
		$(".js-home-hero").each(function() {
			var homeHero = $(this);
			
			$(window).on("scroll resize load", function() {
				// if parallax object is out of view, don't bother changing CSS on it...
				if ($(window).scrollTop() > $(window).height()) {
					return;
				}
				homeHero.css({
					transform: 'translateY(' + ($(window).scrollTop() / 3) + 'px)'
				})
			});
		})
	}

	/******************************************************************************************
	FADE IN ON SCROLL
	******************************************************************************************/
	
	if ($(".js-fade-in").length && screen_size != 'small') {
		$(".js-fade-in").css("opacity", "0");
		$(window).on("scroll resize load", function() {
//			console.log($(window).scrollTop() + ", " +  $(window).height());
			$(".js-fade-in").each(function() {
				if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
					$(this).removeClass("js-fade-in");
					var delay = parseInt($(this).attr("data-js-fade-in-delay")) + 100;
					$(this).delay(delay).animate({"opacity": 1}, 800);
				}
			})
		});
	}

	/******************************************************************************************
	ACCORDIONS
	******************************************************************************************/

	$('.accordion__heading').click(function () {

		var aHeading = $(this);

		// is accordion enabled at this size?
		var disableFrom = aHeading.get(0).hasAttribute('data-disable-accordion-from') ? aHeading.attr('data-disable-accordion-from') : 999999;
		if ($(window).width() >= disableFrom) {
			return;
		}

		aHeading.toggleClass('accordion__heading--is-open');
		// tell our nav not to be effected by any scrolling that's about to happen
		// lockNav();
		// toggle relevant content
		aHeading.next('.accordion__content').slideToggle(400, function () {
			// re-enable nav
			// unlockNav();
		});
		// when we open one, close all others
		// if (aHeading.is('.accordion__heading--is-open')) {
		// 	// calculate where this heading is going to end up so we can scroll to it
		// 	// get current position of heading
		// 	var targetY = aHeading.offset().top;
		// 	// subtract height of any accordion content that's open and above (as page is going to shift up by that much)
		// 	$('.accordion__heading--is-open').each(function () {
		// 		if ($(this).offset().top < aHeading.offset().top) {
		// 			targetY -= $(this).next('.accordion__content').outerHeight(true);
		// 		}
		// 	});
		// 	// also subtract height of nav if it's a sticky one...
		// 	if ($('.site-header').hasClass('site-header--is-fixed')) {
		// 		targetY -= $('.site-header').outerHeight(true);
		// 	}
		// 	// now close all siblings
		// 	$('.accordion__heading--is-open').not(aHeading).removeClass('accordion__heading--is-open').next('.accordion__content').slideUp();
		// 	// and scroll to our open one
		// 	$('html, body').animate({
		// 		scrollTop: targetY
		// 	}, 400, function () {
		// 		// re-enable nav
		// 		// unlockNav();
		// 	});
		// }
	});

	/******************************************************************************************
	Data table ACCORDIONS
	******************************************************************************************/

	$('.data-accordion__head').click(function () {
		var $this = $(this);
		$this.parent('.data-accordion__accordion').toggleClass('open');		
	});

	/******************************************************************************************
	TRUNCATE
	******************************************************************************************/
	
	if ($(".js-truncate").length) {
		$(".js-truncate").each(function() {
			var tTarget = $(this),
				tHeight = tTarget.attr("data-truncate-height");
			tTarget.css("height", tHeight).dotdotdot();
		})
		$(window).on("resize", function() {
			$(".js-truncate").trigger("update");
		});
	}

	/******************************************************************************************
	EQUAL HEIGHTS FOR ARTICLE LISTS
	*******************************************************************************************/

	// $('.article-list__article').matchHeight();

	/******************************************************************************************
	HOME CAROUSEL
	*******************************************************************************************/

	$('.home-hero').each(function() {
		var homeHero = $(this),
			images = $(".home-hero__img"),
			currImg = 0,
			carouselSpeed = 4000,
			fadeSpeed = 1000;
		
		if (images.length > 1) {
			function advanceHomeCarousel() {
				currImg++;
				if (currImg == images.length) currImg = 0;
				// set our new image to be basically invisible but still load in
				images.eq(currImg).css({
					display: "block",
					opacity: ".001",
					zIndex: "1"
				// then fade it in (extra -100 here is to make sure it's faded in before we do next step)
				}).delay(carouselSpeed - fadeSpeed - 100).animate({
					opacity: "1"
				}, fadeSpeed, function() {
					// we don't hide the first image so that if window loses focus and then regains it, we've always got something to show
					images.not(this).not(".home-hero__img--1").hide();
					$(this).css({
						zIndex: "0"
					})
				})
			}
	
			// set timer to update carousel and captions
			var homeTimer = setInterval(function(){advanceHomeCarousel()}, 4000);
			advanceHomeCarousel();
		}
	});

	/******************************************************************************************
	TRIGGER VIMEO ANIMATIONS
	******************************************************************************************/

	// grab all iframes that have vimeo's autoplay query in src
	$('iframe[src*="background=1"]').each(function() {
		var iFrame = $(this),
			player = new Vimeo.Player(iFrame),
			playing = false;

		// on play, if we've not told it to play, pause it. This seems to be only way to guarantee catching Vimeo's autoplay
		player.on('play', function () {
			// console.log('playing');
			if (!playing) {
				player.pause();
				// console.log('cancelled autoplay');
			}
		});
		$(window).on("scroll resize load", function() {
			if ($(window).scrollTop() + $(window).height() > iFrame.offset().top + (iFrame.height() / 2) && !playing) {
				player.play();
				playing = true;
				// console.log('triggered play');
			}
		});
	});

	/******************************************************************************************
	MISC
	******************************************************************************************/
	
	// SHARE LINKS
	
	// if ($("ul.share").length) {
	// 	$("ul.share li:not('.em') a").click(function() {
	// 		window.open($(this).attr("href"), "share", "height=380,width=600,toolbar=0,location=0,menubar=0");
	// 		return false;
	// 	});
	// }

	// SMOOTH SCROLL TO ALL # LINKS
	
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 800);
				return false;
			}
		}
	});

});